



































































































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import FormInput from '@/components/FormInput/FormInput.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import MediaPlanItem from '@/models/MediaPlanItem'
import { integerMask, currencyMask } from '@/models/interface/Masks'
import MediaPackagePicker from '@/components/MediaPackagePicker/MediaPackagePicker.vue'

import {
  ImpressionModelOptions,
  OrderTypeOptions,
  CreativeLength,
  DaysOfWeek,
} from '@/models/interface/Common'
import MediaPlan from '@/models/MediaPlan'
import SelectOption from '@/models/interface/SelectOption'

@Component({
  components: {
    Widget,
    FormInput,
    SelectPicker,
    DatePicker,
    MediaPackagePicker,
  },
})
export default class BatchEditMediaPlanItems extends ViewModel {
  @Prop({ required: true })
  public show!: boolean

  @Prop({ required: true })
  public mediaPlan!: MediaPlan

  private local_media_plan: MediaPlan = new MediaPlan()

  private batch_item: MediaPlanItem = new MediaPlanItem()

  public selected: string[] = []

  private get masks() {
    return {
      integerMask,
      currencyMask,
    }
  }

  public get options() {
    return {
      order_type: OrderTypeOptions,
      impression_model: ImpressionModelOptions,
      demo_targets: this.local_media_plan.metadata.demo_targets.map(
        (d: any, index: number) => new SelectOption(`${d.target}${d.age_low}${d.age_high}`, index),
      ),
      creative_length: CreativeLength,
      daysOfWeek: DaysOfWeek,
    }
  }

  private get local_show() {
    return this.show
  }

  private set local_show(value: boolean) {
    this.$emit('update:show', value)
  }

  @Watch('mediaPlan')
  private onTypeChange(value: MediaPlan) {
    this.local_media_plan = value
  }

  private confirm() {
    this.$emit('confirm', { selected: this.selected, values: this.batch_item })
  }

  public cancel() {
    this.$emit('cancel')
  }

  public isEnabled(name: string) {
    return this.selected.includes(name)
  }

  public created() {
    this.local_media_plan = this.mediaPlan
  }
}
