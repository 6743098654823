import layout from '@/mixins/layout'
import Api from '@/models/Api'
import {
  MediaPlanTypes,
  MediaPlanViewColumns,
  capitalize,
  MediaPlanViewColumnsLinear,
  BillingCycle,
  InvoiceUploadMode,
} from '@/models/interface/Common'
import { percentageMask } from '@/models/interface/Masks'
import MediaPlan from '@/models/MediaPlan'
import LayoutModule from '@/store/LayoutModule'
import SystemtModule from '@/store/SystemModule'
import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'

const { user } = getModule(SystemtModule)

export default {
  name: 'Media Plan',
  submitText: 'Save & Close',
  actions: [
    {
      name: 'Save & Download Proposal XML',
      icon: 'download',
      action: 'saveDownload',
      visible: (media_plan: MediaPlan) => media_plan.isLinear,
    },
  ],
  sections: [
    {
      name: 'Campaign Information',
      icon: 'user',
      actions: [
        /* {
          name: 'Expand/Compact View',
          icon: () => {
            const { view_mode } = getModule(LayoutModule)
            return view_mode === 'compact' ? 'expand' : 'compress'
          },
          action: () => {
            const layoutModule = getModule(LayoutModule)
            layoutModule.toggleViewMode()
          },
        }, */
      ],
      validation: (media_plan: MediaPlan) =>
        new Promise((resolve, reject) => {
          if (media_plan.metadata.demo_targets.length <= 0) {
            return reject(new Error('Please add at least one demo group.'))
          }
          media_plan.metadata.demo_targets.forEach((target: any) => {
            if (target.age_high !== '+' && target.age_low > target.age_high) {
              return reject(new Error('Demo Age range is invalid.'))
            }
          })
          resolve('')
        }),
      fields: [
        {
          name: 'Plan Type',
          type: 'picker',
          target: 'formType',
          rules: 'required',
          options: MediaPlanTypes,
        },
        {
          name: 'Salesforce Opportunity ID',
          type: 'text',
          target: 'salesforce_opportunity_id',
          rules: '',
          visible: (media_plan: MediaPlan) =>
            user && user.profile_id === '4ce1a57c-2e2c-11ea-92b6-0a84a8791ed2',
        },
        {
          name: 'Order Name',
          type: 'text',
          target: 'name',
          rules: 'required|min:3|max:255',
        },
        {
          name: 'Agency',
          type: 'agency',
          rules: 'required',
          target: 'agency_id',
        },
        {
          name: 'Compulse Station',
          type: 'station',
          target: 'station_id',
          visible: (media_plan: MediaPlan) => !media_plan.isLinear,
        },
        {
          name: 'Advertiser',
          type: 'company',
          target: 'advertiser_id',
          rules: 'required|min:3|max:255',
        },
        {
          name: 'Station Call Letters',
          type: 'text',
          target: 'formStationCallLetters',
          rules: 'required|max:4',
          visible: (media_plan: MediaPlan) => media_plan.isLinear,
        },
        {
          name: 'Ad Buyer Company Name',
          type: 'text',
          target: 'contact_company',
          rules: 'required',
        },
        {
          name: 'Ad Buyer Contact Name',
          type: 'text',
          target: 'contact_name',
          rules: 'required',
        },
        {
          name: 'Ad Buyer Contact Title',
          type: 'text',
          target: 'contact_title',
        },
        {
          name: 'Ad Buyer Contact Email',
          type: 'text',
          target: 'contact_email',
        },
        {
          name: 'Sales Management',
          type: 'user',
          target: 'sales_management_id',
        },
        {
          name: 'Sales Rep',
          type: 'user',
          rules: 'required',
          target: 'sales_rep_id',
        },
        {
          name: 'Account Manager',
          type: 'user',
          target: 'account_manager_id',
        },
        {
          name: 'Agency Comission',
          type: 'number',
          target: 'formAgencyCommission',
          mask: percentageMask,
          rules: 'required',
        },
        {
          name: 'Billing Cycle',
          type: 'picker',
          target: 'metadata.billing_cycle',
          options: BillingCycle,
          rules: 'required',
          visible: (media_plan: MediaPlan) => media_plan.status !== 'draft',
        },
        {
          name: 'Show Media Plan Columns',
          type: 'picker',
          target: 'metadata.view_columns',
          options: MediaPlanViewColumns,
          multiple: true,
          visible: (media_plan: MediaPlan) => !media_plan.isLinear,
        },
        {
          name: 'Show Media Plan Columns',
          type: 'picker',
          target: 'metadata.view_columns',
          options: MediaPlanViewColumnsLinear,
          multiple: true,
          visible: (media_plan: MediaPlan) => media_plan.isLinear,
        },
        {
          name: 'Terms',
          type: 'terms',
          sub_type: 'advertiser',
          target: 'formTermsId',
          sub_target: 'formTerms',
          visible: (media_plan: MediaPlan) => media_plan.formType === 'default',
        },
        {
          name: 'Demo Groups',
          type: 'demo_builder',
          target: 'formDemos',
          visible: (media_plan: MediaPlan) => media_plan.formType !== 'default',
        },
      ],
    },
    {
      name: (media_plan: MediaPlan) =>
        `${capitalize(media_plan.type.replaceAll('_', ' '))} Details`,
      visible: (media_plan: MediaPlan) =>
        media_plan.status !== 'draft' && media_plan.type !== 'default',
      icon: 'receipt',
      fields: [
        {
          name: 'Agency (Code 21)',
          type: 'hr',
        },
        {
          name: 'IDB number (ID 2)',
          type: 'text',
          target: 'metadata.agency.id',
          rules: 'required|max:8',
          description: 'Agency name from IDB list',
          onBlur: (media_plan: MediaPlan) => {
            const api = new Api()
            api
              .get(`media_plan/upload_mode/${media_plan.metadata.agency.id}`)
              .then((response: any) => {
                media_plan.metadata.invoice_mode = response.data.result.upload_mode
                if (
                  response.data.result.name
                  && (!media_plan.metadata.agency.name || media_plan.metadata.agency.name === '')
                ) {
                  media_plan.metadata.agency.name = response.data.result.name
                }
                if (
                  response.data.result.address
                  && (!media_plan.metadata.agency.address_line_1
                    || media_plan.metadata.agency.address_line_1 === '')
                ) {
                  media_plan.metadata.agency.address_line_1 = response.data.result.address[0] ?? ''
                  media_plan.metadata.agency.address_line_2 = response.data.result.address[1] ?? ''
                  media_plan.metadata.agency.address_line_3 = response.data.result.address[2] ?? ''
                  media_plan.metadata.agency.address_line_4 = response.data.result.address[3] ?? ''
                }
              })
          },
        },
        {
          name: 'Agency Name (ID 3)',
          type: 'text',
          target: 'metadata.agency.name',
          rules: 'required|max:25',
        },
        {
          name: 'Address Line 1 (ID 4)',
          type: 'text',
          target: 'metadata.agency.address_line_1',
          rules: 'required|max:30',
        },
        {
          name: 'Address Line 2 (ID 5)',
          type: 'text',
          target: 'metadata.agency.address_line_2',
          rules: 'max:30',
        },
        {
          name: 'Address Line 3 (ID 6)',
          type: 'text',
          target: 'metadata.agency.address_line_3',
          rules: 'max:30',
        },
        {
          name: 'Address Line 4 (ID 7)',
          type: 'text',
          target: 'metadata.agency.address_line_4',
          rules: 'max:30',
        },
        {
          name: 'ISCI required for Invoice?',
          type: 'checkbox',
          target: 'metadata.isci_required',
        },
        {
          name: 'Station (Code 22)',
          type: 'hr',
        },
        {
          name: 'Station Call Letters (ID 2)',
          type: 'text',
          target: 'metadata.station.call_letters',
          rules: 'required|max:4',
        },
        {
          name: 'Header (Code 31)',
          type: 'hr',
        },
        {
          name: 'Representative (ID 2)',
          type: 'text',
          target: 'metadata.header.representative',
          rules: 'max:25',
        },
        {
          name: 'Advertiser Name (ID 4)',
          type: 'text',
          target: 'metadata.header.advertiser_name',
          rules: 'required|max:25',
          description: "List as 'Client' appears on IO",
        },
        {
          name: 'Product Name (ID 5)',
          type: 'text',
          target: 'metadata.header.product_name',
          rules: 'required|max:25',
        },
        {
          name: 'Agency Estimate Code (ID 8)',
          type: 'text',
          target: 'metadata.header.agency_estimate_code',
          rules: 'between:1,9999',
          description: 'Not required but strongly recommended.',
        },
        {
          name: 'Period Start Date (ID 11)',
          type: 'text',
          target: 'metadata.header.start_date',
          rules: 'max:6',
          description: 'Include period dates from IO (YYMMDD)',
        },
        {
          name: 'Period End Date (ID 12)',
          type: 'text',
          target: 'metadata.header.end_date',
          rules: 'max:6',
          description: 'Include period dates from IO (YYMMDD)',
        },
        {
          name: 'Station Cash Order Number (ID 23-A)',
          type: 'text',
          target: 'metadata.header.station_order_number',
          rules: 'max:10',
        },
        {
          name: 'Station Trade Order Number (ID 23-B)',
          type: 'text',
          target: 'metadata.header.station_trade_order_number',
          rules: 'max:10',
        },
        {
          name: 'Agency Advertiser Code (ID 25)',
          type: 'text',
          target: 'metadata.header.agency_advertiser_code',
          rules: 'max:8',
          description: 'Not required but strongly recommended.',
        },
        {
          name: 'Agency Product Code (ID 28)',
          type: 'text',
          target: 'metadata.header.agency_product_code',
          rules: 'max:8',
          description: 'Not required but strongly recommended.',
        },
        {
          name: 'Invoicing Mode',
          type: 'picker',
          target: 'metadata.invoice_mode',
          rules: 'required',
          options: InvoiceUploadMode,
        },
      ],
    },
    {
      name: 'Plan Builder',
      icon: 'list',
      validation: (media_plan: MediaPlan) =>
        new Promise((resolve, reject) => {
          media_plan.line_items.forEach(item => {
            if (!item.media_package_id) {
              item._showDetails = true
              return reject(
                new Error(`Please fill in the media package on Line Item #${item.number}`),
              )
            }
            if (media_plan.isLinear) {
              if (!item.creative_length) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the creative length on Line Item #${item.number}`),
                )
              }
              if (!item.media_package_id) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the media package on Line Item #${item.number}`),
                )
              }
              if (!item.metadata.program_name) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the program name on Line Item #${item.number}`),
                )
              }
              if (!item.metadata.flight_time.start_at) {
                item._showDetails = true
                return reject(
                  new Error(`Please fill in the start time on Line Item #${item.number}`),
                )
              }
              if (!item.metadata.flight_time.end_at) {
                item._showDetails = true
                return reject(new Error(`Please fill in the end time on Line Item #${item.number}`))
              }
              if (
                item.impressions > 0
                && item.metadata.spots.reduce((acc: number, spot: number) => acc + Number(spot), 0)
                  <= 0
              ) {
                item._showDetails = true
                return reject(
                  new Error(`Please add at least one spot on Line Item #${item.number}`),
                )
              }

              if (item.metadata.days.length <= 0) {
                item._showDetails = true
                return reject(
                  new Error(`Please add at least one day of the week on Line Item #${item.number}`),
                )
              }
            }
          })
          resolve('')
        }),
      actions: [
        /* {
          name: 'Expand/Compact View',
          icon: () => {
            const { view_mode } = getModule(LayoutModule)
            return view_mode === 'compact' ? 'expand' : 'compress'
          },
          action: () => {
            const layoutModule = getModule(LayoutModule)
            layoutModule.toggleViewMode()
          },
        }, */
        {
          name: 'Toggle All',
          icon: (model: MediaPlan) => (model.line_items[0]._showDetails ? 'eye-slash' : 'eye'),
          action: (model: MediaPlan) => {
            const state = model.line_items[0]._showDetails
            model.line_items.forEach(item => {
              item._showDetails = !state
            })
          },
        },
        {
          name: 'Save (CTRL+S)',
          icon: 'save',
          action: 'save',
        },
        {
          name: 'Generate Trade Line Item',
          icon: 'calendar-plus',
          action: 'generate-trade',
          visible: (model: MediaPlan) => model.isLinear,
        },
        {
          name: 'Batch Edit',
          icon: 'edit',
          action: 'batch',
        },
        {
          name: 'Add Line Item',
          icon: 'plus',
          action: (model: MediaPlan) => model.addLineItem(),
        },
      ],
      fields: [
        {
          name: 'Line Items',
          type: 'media_plan_builder',
        },
      ],
    },
  ],
}
