
















































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import MediaPlan from '@/models/MediaPlan'
import DataForm from '@/components/DataForm/DataForm.vue'
import { MediaPlanProposalActions } from '@/models/interface/Common'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import moment from 'moment'
import MediaPlanItem from '@/models/MediaPlanItem'
import IconAction from '@/components/IconAction/IconAction.vue'
import { isUndefined, clone as _clone } from 'lodash'
import WebMessage from '@/models/WebMessage'
import BigNumber from 'bignumber.js'
import LayoutModule from '@/store/LayoutModule'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import BatchEditMediaPlanItems from './components/BatchEditMediaPlanItems.vue'
import formData from './form'

@Component({
  components: {
    Widget,
    DataForm,
    BatchEditMediaPlanItems,
    SelectPicker,
    IconAction,
  },
})
export default class MediaPlanEdit extends ViewModel {
  @Prop({ default: null })
  public id!: string

  private ref: string = 'MediaPlans'

  private busy = false

  public opportunity: any = {}

  public modal: boolean = false

  public spots_modal: boolean = false

  public download_confim: boolean = false

  public generate_trade: boolean = false

  public trade_percentage: number = 15

  public trade_generator_mode: string = 'one_to_one' // one_to_one, single, one_per_month

  private model = new MediaPlan()

  private container: boolean = true

  private get view_mode() {
    const { view_mode } = getModule(LayoutModule)
    return view_mode
  }

  private get title() {
    return this.id ? 'Edit Media Plan' : 'Create Media Plan'
  }

  private get form() {
    return formData
  }

  private get is_confirm() {
    const { query } = this.$route
    return !isUndefined(query.confirm)
  }

  public get proposalOptions() {
    return MediaPlanProposalActions
  }

  // @Watch('model.salesforce_opportunity_id')
  public onChangeOpportunityID() {
    let valid = false
    if (typeof this.model.salesforce_opportunity_id === 'string') {
      valid = /[a-zA-Z0-9]{15}|[a-zA-Z0-9]{18}/.test(this.model.salesforce_opportunity_id)
    }

    if (!valid) {
      this.loading = false
      return
    }

    this.loading = true
    this.model
      .getOpportunityData()
      .then(response => {
        this.opportunity = response.data.result
        if (!this.id) {
          this.model.name = this.opportunity.name
          this.model.sales_rep_id = this.opportunity.sales_rep_id
          this.model.formType = this.opportunity.type
          this.loading = false
        }
      })
      .catch(error => {
        this.loading = false
      })
  }

  private created() {
    this.loading = true
    const { query } = this.$route

    if (query.ref && typeof query.ref === 'string') {
      this.ref = query.ref
    }

    if (this.id) {
      let promises = [MediaPlan.find(this.id)]
      if (query.order_cash || query.order_trade) {
        promises.push(MediaPlan.processOrderFiles(query.order_cash, query.order_trade))
      }
      Promise.allSettled(promises).then((responses: any) => {
        if (responses[0].value instanceof MediaPlan) {
          this.model = responses[0].value
          this.setAccountManager()
          if (!isUndefined(query.confirm)) {
            this.model.status = 'pending_documents'
          }
          if (responses[1]) {
            this.model.status = 'confirmed'
            this.model.loadOrderData(responses[1].value.data.result)
          }
          this.loading = false
        }
      })
    } else if (query.from && typeof query.from === 'string') {
      MediaPlan.find(query.from).then(media_plan => {
        if (media_plan instanceof MediaPlan) {
          const base = new MediaPlan()

          this.model = media_plan.clone()
          this.model.metadata = { ...base.metadata }
          this.model.id = ''
          this.model.status = 'draft'
          this.model.metrics = {}

          this.model.line_items = this.model.line_items.map(line_item => {
            line_item = line_item.clone()
            line_item.id = ''
            line_item.metrics = {}
            return line_item
          })
          this.loading = false
        }
      })
    } else if (query.sf_opporttunity && typeof query.sf_opporttunity === 'string') {
      this.model.salesforce_opportunity_id = query.sf_opporttunity

      this.setAccountManager()
    } else {
      this.setAccountManager()
      this.loading = false
    }
  }

  public cancel() {
    this.$router.back()
  }

  public showBatchModal() {
    this.modal = true
  }

  public showGenerateTrade() {
    // Check data first
    let has_errors = false
    let message = ''

    if (this.model.elegible_trade_spots === 0) {
      has_errors = true
      message = 'Invalid Media Plan fligth dates! Please make sure that the plan has at least one full week in a month before generating a trade item.'
    }

    if (!has_errors) {
      this.model.line_items.forEach(line_item => {
        if (line_item.impressions == 0 || line_item.total_spots == 0) {
          has_errors = true
          line_item._showDetails = true
          message = 'Please make sure that all Line Items have impressions and at least one spot before generating trade item.'
        }
      })
    }

    if (!has_errors) {
      this.generate_trade = true
    } else {
      WebMessage.error(message)
    }
  }

  public confirmGenerateTrade() {
    // one_to_one, single, one_per_month
    if (this.trade_generator_mode === 'one_per_month') {
      this.generateMultipleTrade()
    } else if (this.trade_generator_mode === 'single') {
      this.generateSingleTrade()
    } else {
      this.generateOneToOneTrade()
    }
  }

  public generateOneToOneTrade() {
    let trade_items: MediaPlanItem[] = []
    let impressions = +this.model.bnImpressions.times(this.trade_percentage / 100).integerValue()
    let rate = _clone(this.model.gross_rate)
    let elegible_spots = _clone(this.model.elegible_trade_spots)
    let new_items = true

    if (this.model.has_trade_item) {
      trade_items = this.model.line_items.filter(
        line_item => line_item.metadata.order_type === 'trade',
      )

      // Restore Impressions Back to Regular Line Items
      if (trade_items.length > 0) {
        new_items = false
        let cash_impressions = this.model.impressions - +impressions
        this.model.line_items.forEach((line_item: MediaPlanItem) => {
          if (line_item.metadata.order_type !== 'trade') {
            line_item.formSpotImpressions += +line_item.bnImpressions
              .div(cash_impressions)
              .times(impressions)
              .div(line_item.total_spots)
              .integerValue()
          }
        })
      }
    }

    // Build Trade Items, runs on timeout to avoid blocking the UI
    setTimeout(() => {
      let i = 0
      this.model.line_items
        .filter(line_item => line_item.metadata.order_type !== 'trade')
        .forEach((item: MediaPlanItem) => {
          // Calculate Trade Item Impressions
          let trade_impressions = +item.bnImpressions
            .times(this.trade_percentage / 100)
            .integerValue()

          // Fetch next Trade Item, if not found, create a new one
          let trade_item: MediaPlanItem | undefined
          if (trade_items[i++]) {
            trade_item = this.model.line_items.find(
              line_item => line_item.id === trade_items[i - 1].id,
            )
          }
          if (!trade_item) {
            trade_item = this.model.addLineItem()
          }

          // Set Trade Item Properties
          trade_item.name = `${item.name} - SSL 2 Trade`
          trade_item.metadata.order_type = 'trade'
          trade_item.metadata.program_name = 'SSL 2'
          trade_item.metadata.demo_target = _clone(item.metadata.demo_target)
          trade_item.media_package_id = '9619a1c2-4423-4d9a-bd71-8b7a051d7868'
          trade_item.notes = _clone(item.notes)
          trade_item.start_at = _clone(item.start_at)
          trade_item.end_at = _clone(item.end_at)
          trade_item.metadata.spots = _clone(item.metadata.spots)
          trade_item.gross_rate = _clone(item.gross_rate)
          trade_item._showDetails = true
          trade_item.formImpressions = trade_impressions

          // Remove Impressions from Cash Line Items
          item.formImpressions -= trade_impressions
        })
      WebMessage.success(
        `Trade Items ${
          new_items ? 'created' : 'updated'
        }! Please review all Line Items as Impressions & Cost might have changed due to number rounding.`,
      )
    }, 350)
  }

  public generateMultipleTrade() {
    let trade_items: MediaPlanItem[] = []
    let impressions = +this.model.bnImpressions.times(this.trade_percentage / 100).integerValue()
    let rate = _clone(this.model.gross_rate)
    let elegible_spots = _clone(this.model.elegible_trade_spots)
    let new_items = true

    if (this.model.has_trade_item) {
      trade_items = this.model.line_items.filter(
        line_item => line_item.metadata.order_type === 'trade',
      )

      // Restore Impressions Back to Regular Line Items
      if (trade_items.length > 0) {
        new_items = false
        let cash_impressions = this.model.impressions - +impressions
        this.model.line_items.forEach((line_item: MediaPlanItem) => {
          if (line_item.metadata.order_type !== 'trade') {
            line_item.formSpotImpressions += +line_item.bnImpressions
              .div(cash_impressions)
              .times(impressions)
              .div(line_item.total_spots)
              .integerValue()
          }
        })
      }
    }

    // Build Spot Dates
    let trade_dates: any = []
    let pointer = moment(this.model.start_at)

    let included_months: string[] = []
    while (pointer.isBefore(this.model.end_at)) {
      if (!included_months.includes(pointer.format('YYMM'))) {
        let end = pointer.clone().add(1, 'weeks')

        if (pointer.month() === end.month()) {
          trade_dates.push({
            start_at: pointer.format('YYYY-MM-DD'),
            end_at: end.format('YYYY-MM-DD'),
          })

          included_months.push(pointer.format('YYMM'))
        }
      }

      pointer.add(1, 'week')
    }

    let i = 0
    for (; i < elegible_spots; i++) {
      let trade_item: MediaPlanItem | undefined
      if (trade_items[i]) {
        trade_item = this.model.line_items.find(line_item => line_item.id === trade_items[i].id)
      }

      if (!trade_item) {
        trade_item = this.model.addLineItem()
      }
      trade_item.name = `Trade ${i + 1}`
      trade_item.metadata.order_type = 'trade'
      trade_item.metadata.program_name = 'SSL 2'
      trade_item.metadata.demo_target = 0
      trade_item.media_package_id = '9619a1c2-4423-4d9a-bd71-8b7a051d7868'
      trade_item.notes = '100% Live Games'
      trade_item.start_at = trade_dates[i].start_at
      trade_item.end_at = trade_dates[i].end_at
      trade_item.gross_rate = rate
      trade_item._showDetails = true
    }

    for (; i < trade_items.length; i++) {
      trade_items[i].impressions = 0
    }

    // Remove Impressions from Cash Line Items
    this.model.line_items
      .filter(line_item => line_item.metadata.order_type !== 'trade')
      .forEach((item: MediaPlanItem) => {
        item.formSpotImpressions = +item.bnImpressions
          .times(
            new BigNumber(this.trade_percentage)
              .div(100)
              .negated()
              .plus(1),
          )
          .div(item.total_spots)
          .integerValue()
      })
    setTimeout(() => {
      this.model.line_items
        .filter(line_item => line_item.metadata.order_type === 'trade')
        .forEach((item: MediaPlanItem) => {
          item.metadata.spots = [1]
          item.formSpotImpressions = +new BigNumber(impressions).div(elegible_spots).integerValue()
        })

      WebMessage.success(
        `Trade Items ${
          new_items ? 'created' : 'updated'
        }! Please review all Line Items as Impressions & Cost might have changed due to number rounding.`,
      )
    }, 100)
  }

  public generateSingleTrade() {
    let trade_item: MediaPlanItem | undefined
    let impressions = +this.model.bnImpressions.times(this.trade_percentage / 100).integerValue()
    let start_at = _clone(this.model.start_at)
    let end_at = _clone(this.model.end_at)
    let rate = _clone(this.model.gross_rate)
    let new_item = true

    if (this.model.has_trade_item) {
      trade_item = this.model.line_items.find(
        line_item => line_item.metadata.order_type === 'trade',
      )

      // Restore Impressions Back to Regular Line Items
      if (trade_item) {
        new_item = false
        let i = trade_item.bnImpressions
        let cash_impressions = this.model.impressions - +impressions
        this.model.line_items.forEach((item: MediaPlanItem) => {
          if (trade_item && item.number !== trade_item.number) {
            item.formSpotImpressions += +item.bnImpressions
              .div(cash_impressions)
              .times(impressions)
              .div(item.total_spots)
              .integerValue()
          }
        })
      }
    }

    if (!trade_item) {
      trade_item = this.model.addLineItem()
      trade_item.metadata.order_type = 'trade'
      trade_item.name = 'Trade'
      trade_item.metadata.program_name = 'SSL 2'
      trade_item.metadata.demo_target = 0
      trade_item.media_package_id = '9619a1c2-4423-4d9a-bd71-8b7a051d7868'
      trade_item.notes = '100% Live Games'
    }

    trade_item.formStartAt = start_at
    trade_item.formEndAt = end_at

    // Set Spots
    let months: string[] = []
    let pointer = moment(this.model.start_at)
    let week_index = 0
    while (
      pointer.isBefore(this.model.end_at)
      && trade_item.total_spots < this.model.elegible_trade_spots
    ) {
      if (!months.includes(`${pointer.month()}${pointer.year()}`)) {
        let can_have_spot = false
        let elegible_line_items = this.model.line_items.filter(line_item =>
          pointer.isBetween(line_item.start_at, line_item.end_at, undefined, '[]'))

        for (let key in elegible_line_items) {
          let line_item = elegible_line_items[key]

          let index = pointer.diff(line_item.start_at, 'weeks')

          if (line_item.metadata.spots[index] >= 1) {
            can_have_spot = true
            break
          }
        }

        if (can_have_spot) {
          trade_item.metadata.spots[week_index] = 1
          months.push(`${pointer.month()}${pointer.year()}`)
        }
      }

      pointer.add(1, 'week')
      week_index++
    }

    this.model.line_items.forEach((item: MediaPlanItem) => {
      item.formSpotImpressions = +item.bnImpressions
        .times(
          new BigNumber(this.trade_percentage)
            .div(100)
            .negated()
            .plus(1),
        )
        .div(item.total_spots)
        .integerValue()
    })

    trade_item.formSpotImpressions = +new BigNumber(impressions)
      .div(trade_item.total_spots)
      .integerValue()

    trade_item.formGrossRate = rate
    trade_item._showDetails = true

    WebMessage.success(
      `Trade Item ${
        new_item ? 'created' : 'updated'
      }! Please review all Line Items as Impressions & Cost might have changed due to number rounding.`,
    )
  }

  public weekPeriod(week: number) {
    const start = moment(this.model.start_at).clone()
    start.add(week, 'weeks')
    if (week > 0) {
      start.startOf('week').add(1, 'days')
    }
    return `${start.format('MM/DD')} - ${start
      .clone()
      .endOf('week')
      .add(1, 'days')
      .format('MM/DD')}`
  }

  public setAccountManager() {
    if (this.model && !this.model.account_manager_id) {
      this.model.account_manager_id = this.user.id
    }
  }

  public batchConfirm(data: any) {
    this.modal = false
    this.model.line_items = this.model.line_items.map(item => {
      data.selected.forEach((prop: string) => {
        // @ts-ignore
        if (prop.includes('flight_time')) {
          const path = prop.split('.')
          // @ts-ignore
          item[path[0]][path[1]][path[2]] = data.values[path[0]][path[1]][path[2]]
        } else if (prop.includes('metadata')) {
          const path = prop.split('.')
          // @ts-ignore
          item[path[0]][path[1]] = data.values[path[0]][path[1]]
        } else if (prop === 'impressions') {
          if (this.model.isLinear) {
            item.formSpotImpressions = data.values[prop]
          } else {
            item.formImpressions = data.values[prop]
          }
        } else if (prop === 'net_rate') {
          item.formNetRate = data.values[prop]
        } else if (prop === 'gross_rate') {
          item.formGrossRate = data.values[prop]
        } else if (prop === 'net_cost') {
          item.formNetCost = data.values[prop]
        } else if (prop === 'gross_cost') {
          item.formGrossCost = data.values[prop]
        } else {
          // @ts-ignore
          item[prop] = data.values[prop]
        }
      })
      item._showDetails = false
      return item
    })
    setTimeout(() => {
      this.model.line_items = this.model.line_items.map(item => {
        item._showDetails = true
        return item
      })
    }, 500)
  }

  public onSubmit(model: MediaPlan) {
    this.busy = true
    model.download_on_save = false
    model
      .save()
      .then(response => {
        this.completeSave(response, true)
      })
      .catch(error => {
        this.busy = false
      })
  }

  private clearHomeFilters() {
    const { query } = this.$route
    if (query.from) {
      const system = getModule(SystemtModule)
      system.updateState({
        name: 'filters',
        type: 'media_plan',
        data: null,
      })
    }
  }

  public onSave(model: MediaPlan) {
    this.busy = true
    model.download_on_save = false
    model
      .save()
      .then(response => {
        this.completeSave(response, false)
      })
      .catch(error => {
        this.busy = false
      })
  }

  private completeSave(response: any, close: boolean) {
    this.clearHomeFilters()
    if (response.status == 200) {
      this.busy = false
      if (close) {
        this.$router.push({ name: this.ref })
      } else {
        this.model = MediaPlan.toObject(response.data.result.media_plan)
      }
    }
  }

  public confirmSaveDownload() {
    this.busy = true
    this.clearHomeFilters()

    this.model.save().then(response => {
      this.busy = false
      if (response.status == 200) {
        this.$router.push({ name: this.ref })
      }
    })
  }

  public onSubmitDownload(model: MediaPlan) {
    model.download_on_save = true
    this.model = model

    /*
    this.download_confim = true
    */

    return this.confirmSaveDownload()
  }

  private getWeekDates(start_at: string, week: number): string {
    const start = moment(start_at).add(week, 'weeks')
    return `${start.format('MM/DD/YYYY')} ~ ${start
      .endOf('week')
      .add(1, 'days')
      .format('MM/DD/YYYY')}`
  }

  private lineItemSpotIndex(line_item: MediaPlanItem, index: number): boolean | number {
    let pointer = moment(this.model.start_at)
      .startOf('week')
      .add(index, 'weeks')
      .add(1, 'day')
    let start = moment(line_item.start_at)
      .startOf('week')
      .add(1, 'day')
    let end = moment(line_item.end_at)
      .subtract(1, 'day')
      .endOf('week')
      .add(1, 'day')

    let ret: boolean | number = false

    if (pointer.isBetween(start, end, undefined, '[]')) {
      ret = 0
      while (pointer.isAfter(start)) {
        start.add(1, 'weeks')
        ret++
      }
    }

    return ret
  }

  // Register Ctrl+s to save
  public mounted() {
    document.onkeydown = e => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault()
        this.onSave(this.model)
      }
    }
  }

  /**
   * Clear events to prevent triggering the event outside the page
   */
  private beforeDestroy() {
    document.onkeydown = null
  }
}
