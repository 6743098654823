




















































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import MediaPlan from '@/models/MediaPlan'
import MediaPlanHeaderProperty from './MediaPlanHeaderProperty.vue'

@Component({
  components: {
    MediaPlanHeaderProperty,
  },
})
export default class MediaPlanPrintView extends Vue {
  @Prop()
  public media_plan!: MediaPlan
}
