





















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import MediaPlan from '@/models/MediaPlan'
import moment from 'moment'
import MediaPlanHeaderProperty from './MediaPlanHeaderProperty.vue'

@Component({
  components: {
    MediaPlanHeaderProperty,
  },
})
export default class MediaPlanScheduleView extends Vue {
  @Prop()
  public media_plan!: MediaPlan

  private sortBy = 'start_at'

  private sortDesc = false

  private fields = [
    {
      key: 'code',
      label: 'Code',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      key: 'start_at',
      label: 'Start Date',
      sortable: true,
    },
    {
      key: 'end_at',
      label: 'End Date',
      sortable: true,
    },
    {
      key: 'impressions',
      label: 'Impressions',
      sortable: true,
      formatter: (value: number) => value.toLocaleString(),
    },
    {
      key: 'net_rate',
      label: 'Net Rate',
      sortable: true,
      formatter: (value: number) => `$ ${value.toFixed(2)}`,
    },
    {
      key: 'notes',
      label: 'Notes',
      sortable: true,
    },
  ]

  private schedule: any = []

  public updateSchedule() {
    /* if (this.media_plan.total_spots > 0) {
      let schedule: any = []
      this.media_plan.line_items.forEach(line_item => {
        line_item.metadata.spots.forEach((spot: number, index: number) => {
          let week = this.lineItemSpotIndex(line_item.start_at, index)
          let dates = this.getWeekDates(line_item.start_at, week)
          for (let i = 1; i <= spot; i++) {
            schedule.push({
              code: `${this.media_plan.number}-${String(line_item.number).padStart(
                3,
                '0',
              )}-${String(week).padStart(3, '0')}${spot > 1 ? `-${i + 1}` : ''}`,
              name: `Week ${week}: ${dates.start_at.format('MM/DD/YY')} - ${dates.end_at.format(
                'MM/DD/YY',
              )}`,
              start_at: dates.start_at.format('YYYY-MM-DD'),
              end_at: dates.end_at.format('YYYY-MM-DD'),
              impressions: line_item.formSpotImpressions,
              net_rate: line_item.net_rate,
              notes: line_item.notes,
            })
          }
        })
      })

      this.schedule = schedule
    } */

    this.schedule = this.media_plan.formSchedule
    /* this.media_plan.line_items.map(line_item => {
      let week = this.lineItemSpotIndex(line_item.start_at, 0)

      let start = moment(line_item.start_at)
      let end = moment(line_item.end_at)
      if (this.media_plan.isLinear) {
        let pointer = start.clone()
        let itemEnd = end.clone()
        let lockStart = false
        while (pointer.isSameOrBefore(itemEnd)) {
          if (line_item.metadata.days.includes(pointer.format('dddd').toLowerCase())) {
            if (!lockStart) {
              lockStart = true
              start = pointer.clone()
            }
            end = pointer.clone()
          }

          pointer.add(1, 'day')
        }
      }

      let name = `${line_item.name}: ${start.format('MM/DD')} - ${end.format('MM/DD')}`
      if (line_item.metadata.program_name) {
        name = `${line_item.metadata.program_name} (Week ${week}): ${start.format(
          'MM/DD',
        )} - ${end.format('MM/DD')}`
      }

      return {
        code: `${String(this.media_plan.number).padStart(
          String(this.media_plan.number).length + 1,
          '0',
        )}-${String(line_item.number).padStart(3, '0')}`,
        name,
        start_at: start.format('YYYY-MM-DD'),
        end_at: end.format('YYYY-MM-DD'),
        days: line_item.metadata.days
          .map((day: string) => day.slice(0, 1).toUpperCase() + day.slice(1, 3))
          .join(', '),
        impressions: line_item.impressions,
        net_rate: line_item.net_rate,
        notes: line_item.notes,
      }
    }) */
  }

  private lineItemSpotIndex(start_at: string, index: number): number {
    let pointer = moment(this.media_plan.start_at)
      .startOf('week')
      .add(index, 'weeks')
      .add(1, 'day')
    let start = moment(start_at)
      .startOf('week')
      .add(1, 'day')

    if (index > 0) {
      start
        .add(index, 'weeks')
        .startOf('week')
        .add(1, 'day')
    }

    let ret: number = 1 + index

    while (pointer.isBefore(start)) {
      pointer.add(1, 'weeks')
      ret++
    }

    return ret
  }

  private getWeekDates(start_at: string, week: number): any {
    const start = moment(start_at).add(week, 'weeks')
    return {
      start_at: start,
      end_at: start
        .clone()
        .endOf('week')
        .add(1, 'days'),
    }
  }

  public created() {
    this.updateSchedule()
  }
}
