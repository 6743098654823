


































































































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import MediaPlan from '@/models/MediaPlan'
import ReconciliationGroup from '@/models/ReconciliationGroup'
import ViewModel from '@/models/ViewModel'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import { clone } from 'lodash'
import ExportMediaPlan from '@/pages/Sales/MediaPlan/components/ExportMediaPlan.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ReconciliationMonthly from '@/models/ReconciliationMonthly'
import options from './options'

@Component({
  components: {
    Widget,
    IconAction,
    SearchInput,
    DataTable,
    SelectPicker,
  },
})
export default class MediaPlanHome extends ViewModel {
  // dataTable Ref
  @Ref() readonly dataTable!: HTMLFormElement

  // Target Media Plan during action event
  public media_plan: MediaPlan = new MediaPlan()

  // Total Number of records
  public records: number = 0

  // Loading incicator
  public loading: boolean = false

  // Search Input
  public query: string[] = []

  // Selected rows
  public selected: string[] = []

  // dataTable field filters
  public fieldFilters: any = {}

  // table data
  public table_data: MediaPlan[] = []

  // Used to determine which detail view to show
  public detail_mode = 'schedule'

  // Indicates is component is initialized and ready to load data
  public ready = false

  // Media Plan Module Options (Search options, Action options, etc)
  public get options() {
    return options
  }

  // List of fields, populated on component mount
  public fields: Array<any> = []

  // Return active fields to the table
  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  // Return Promise with DataTable data
  public rows(context: any): Promise<any> {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()
    return MediaPlan.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.table_data = result.data
      return result.data
    })
  }

  // Redirect user to the create page
  public createMediaPlan() {
    this.$router.push('/app/sales/media_plan')
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.clearFilters()
    this.refresh()
  }

  // Initialize vars
  public mounted() {
    this.fields = clone(MediaPlan.tableFields)
    this.loadFilters()
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_plan',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('media_plan').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'media_plan',
      data: null,
    })
  }

  // Open Batch export dialog
  public exportMediaPlans() {
    let Component = Vue.extend(ExportMediaPlan)
    let instance = new Component({
      propsData: {
        media_plans: this.table_data.filter(
          (media_plan: MediaPlan) =>
            typeof media_plan.id === 'string' && this.selected.includes(media_plan.id),
        ),
        mode: 'single',
      },
    })
    instance.$mount()
  }
}
