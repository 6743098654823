













































import { Component, Prop } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import MediaPlan from '@/models/MediaPlan'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import MediaPlanPrintView from './components/MediaPlanPrintView.vue'
import MediaPlanScheduleView from './components/MediaPlanScheduleView.vue'

@Component({
  components: {
    Widget,
    MediaPlanPrintView,
    MediaPlanScheduleView,
  },
})
export default class MediaPlanView extends ViewModel {
  @Prop()
  public id!: string

  @Prop({ default: 'plan' })
  public mode!: string

  private ref: string = 'MediaPlans'

  public busy = true

  public media_plan: MediaPlan | null = null

  public local_mode: string = 'plan';

  public download() {
    if (this.media_plan) {
      this.media_plan
        .export('io', {
          io_files: ['system'],
        })
        .then(() => {
          WebMessage.success('Generating IO, do not close this window!')
        })
    }
  }

  public editMediaPlan() {
    if (this.media_plan) this.$router.push(`/app/sales/media_plan/${this.id}`)
  }

  public back() {
    this.$router.push({ name: this.ref })
  }

  public created() {
    const { query } = this.$route

    if (query.ref && typeof query.ref === 'string') {
      this.ref = query.ref
    }

    if (!this.id) {
      this.$router.push({ name: this.ref })
      return
    }

    this.local_mode = this.mode

    MediaPlan.find(this.id, { include_details: true }).then(o => {
      if (o instanceof MediaPlan) {
        this.media_plan = o
      } else {
        this.$router.push({ name: this.ref })
        return
      }

      this.busy = false
    })
  }
}
